<template>
  <div
    class="relative hover:border-b py-2"
    :class="isLoadingFile ? 'cursor-wait' : 'cursor-pointer text-primary'"
    @click="downloadFile"
  >
    {{ file.policyFileName }}
    <div
      v-if="isLoadingFile"
      class="absolute top-0 right-0"
    >
      <BSpinner />
    </div>
  </div>
</template>

<script>
import { saveFile } from '@/helpers/utils';
import PolicyServices from '@/services/policy/policy.service';

const policyServices = new PolicyServices();

export default {
  props: {
    file: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isLoadingFile: false,
    };
  },
  methods: {
    async downloadFile() {
      if (!this.isLoadingFile) {
        const { id, policyFileName } = this.file;

        console.log(id);
        console.log(policyFileName);

        try {
          this.isLoadingFile = true;
          const { data } = await policyServices.file(id);

          saveFile(data, { name: policyFileName });
        } finally {
          this.isLoadingFile = false;
        }
      }
    },
  },
};
</script>
